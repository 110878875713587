// @flow
import React, {useMemo} from 'react';
import {isMobile} from 'react-device-detect';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {ProductBackgroundImage} from 'components';
import styled from 'styled-components';

type ProductDetailType = {
    product: {
        typeImage: string,
        typeName: string,
        productNumber: number,
        note: string,
        cabinet: {
            description: string,
        },
        extMaterial: {
            image: string,
            type: number,
            brandName: string,
            finish: string,
            name: string,
        },
        carcMaterial: {
            type: number,
            brandName: string,
            finish: string,
            name: string,
        },
        comment: string,
        carcCustomColour: string,
        changedImage: string,
        extCustomColour: string,
    },
};

const NON_SUPPLY: number = 7;
const CUSTOM_COLOUR_NAME: string = 'custom colour';

const BorderImage = styled.div`
    width: ${({$isMobile}) => ($isMobile ? '100px' : '150px')};
    height: ${({$isMobile}) => ($isMobile ? '100px' : '150px')};
    outline-width: 1px;
    outline-style: solid;
    outline-color: rgb(var(--primary_colour));
    border-radius: 10px;
`;

export const ProductDetail = ({
    product,
}: ProductDetailType): React$Element<any> => {
    const isSmallDevice = isDeviceSmall();
    const [exteriorLabel, interiorLabel] = useMemo(() => {
        return [
            !isMobile ? 'Exterior Material:' : 'Ext:',
            !isMobile ? 'Carcase Material:' : 'Carc:',
        ];
    }, [isMobile]);

    const backgroundImage = `url("/uploads/gocabinet_materials/${
        product && product.extMaterial.image != ''
            ? product.extMaterial.image
            : 'default_exterior.jpg'
    }")`;

    const imageContainerStyle = isMobile
        ? {
              height: 'auto',
              maxHeight: '125px',
              minHeight: '100px',
              width: '100px',
          }
        : {};

    return (
        <div className="product-detail">
            <BorderImage $isMobile={isMobile}>
                <ProductBackgroundImage
                    style={{borderRadius: '10px'}}
                    image={backgroundImage}
                    transform={product.extHorGrain}
                    src={product.changedImage}
                    fallbackSrc={product.typeImage}
                    alt={product?.typeName}
                />
            </BorderImage>
            <div style={isSmallDevice ? {} : {minWidth: '200px'}}>
                <h3>
                    <strong>
                        {!product.note ? product.typeName : product.note}
                    </strong>
                </h3>
                {!isMobile ? (
                    <p>
                        <i>{product.cabinet.description}</i>
                    </p>
                ) : null}
                {product.extMaterial.type != NON_SUPPLY ? (
                    <>
                        <h3 style={{fontSize: '12px', marginBottom: '0px'}}>
                            {exteriorLabel}{' '}
                        </h3>
                        <p>
                            {product.extMaterial.name}{' '}
                            {product.extMaterial.name
                                .toLowerCase()
                                .indexOf(CUSTOM_COLOUR_NAME) > -1 &&
                                product.extCustomColour + ' '}
                            <em>{product.extMaterial.finish}</em> -{' '}
                            {product.extMaterial.thickness}mm{' '}
                            {product.extMaterial.brandName}{' '}
                            {product.extMaterial.substrate &&
                                product.extMaterial.substrate + ' '}
                        </p>
                    </>
                ) : null}
                {product.carcMaterial.type != NON_SUPPLY ? (
                    <>
                        <h3 style={{fontSize: '12px', marginBottom: '0px'}}>
                            {interiorLabel}{' '}
                        </h3>
                        <p>
                            {product.carcMaterial.name}{' '}
                            {product.carcMaterial.name
                                .toLowerCase()
                                .indexOf(CUSTOM_COLOUR_NAME) > -1 &&
                                product.extCustomColour + ' '}
                            <em>{product.carcMaterial.finish}</em> -{' '}
                            {product.carcMaterial.thickness}mm{' '}
                            {product.carcMaterial.brandName}{' '}
                            {product.carcMaterial.substrate &&
                                product.carcMaterial.substrate + ' '}
                        </p>
                    </>
                ) : null}
                {product.comment ? (
                    <p>
                        <strong>Variation: </strong>
                        <span style={{color: '#FF0000'}}>
                            {product.comment}{' '}
                        </span>
                    </p>
                ) : null}
            </div>
        </div>
    );
};
